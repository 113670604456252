<template>
  <div v-loading="loadingFlag">
    <el-form :model="suitForm" ref="ruleForm" label-width="120px" size="mini" class="demo-ruleForm">
      <el-row class="vg_pl_16 vg_mt_16 vd_disp">
        <el-col
          class="vd_col vg_mb_16"
          :offset="offestList[index]"
          :md="clacWidth"
          v-for="(item, index) in suitForm.list"
          :key="item.swst_id"
        >
          <!--          <el-row>
            <el-col :md="24">
              <el-form-item
                label="岗位名称"
                label-width="90px"
                :prop="'list.' + index + '.swst_name'"
                :rules="[{ required: true, message: ' ' }]"
              >
                <el-input v-model="suitForm.list[index].swst_name" maxlength="30" show-word-limit placeholder="暂无产品名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>-->
          <el-row>
            <el-col :md="24">
              <el-form-item
                label="套装名称"
                label-width="90px"
                :prop="'list.' + index + '.swst_name'"
                :rules="[{ required: true, message: ' ' }]"
              >
                <el-input v-model="suitForm.list[index].swst_name" maxlength="30" show-word-limit placeholder="暂无产品名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <el-form-item
                label="套装编码"
                label-width="90px"
                :prop="'list.' + index + '.swst_no'"
                :rules="[{ required: true, message: ' ' }]"
              >
                <el-input v-model="suitForm.list[index].swst_no" maxlength="30" show-word-limit placeholder="暂无产品名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_pt_8 vg_mb_8">
            <el-col :md="12" :offset="1">
              <el-button type="primary" size="mini" @click="addSharpWeapon(index)">新增利器</el-button>
            </el-col>
          </el-row>
          <el-row v-if="item.swst_cont_list.length > 0">
            <el-row class="vg_mb_8" v-for="(itemName, ind) in item.swst_cont_list" :key="itemName.swst_cont_id">
              <el-col :md="12">
                <div><span class="vd_required">*</span> 选择套装内利器</div>
                <el-select
                  @change="swstContNameChange(index, ind)"
                  v-model="suitForm.list[index].swst_cont_list[ind].swst_cont_name"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option v-for="item in shewNameList" :key="item.id" :label="item.param2" :value="item.param2"> </el-option>
                </el-select>
              </el-col>
              <el-col :md="8" :offset="1">
                <div>数量</div>
                <el-input-number
                  class="el-input-number"
                  size="small"
                  v-model="suitForm.list[index].swst_cont_list[ind].swst_cont_num"
                  @change="handleChange(index, ind)"
                  :step="1"
                  step-strictly
                  :min="1"
                  label="描述文字"
                ></el-input-number>
              </el-col>
              <el-col :md="2" :offset="1">
                <div class="vd_col_copy">1</div>
                <div class="vd_delete">
                  <i class="el-icon-delete" @click="deleteSwstCont(index, ind)"></i>
                </div>
              </el-col>
            </el-row>
          </el-row>
          <el-row class="vg_mb_8" v-else>
            <span>暂无利器</span>
          </el-row>
        </el-col>
        <el-col class="vd_col vg_mb_8 vd_col_add vg_cursor" :offset="offtestIndex" :md="clacWidth">
          <i class="el-icon-plus" @click="addSuit"></i>
        </el-col>
      </el-row>
      <el-row class="vd_row">
        <el-button type="danger" @click="submit('ruleForm')">保存</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { swstAPI } from '@api/modules/swst';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'SuitList',
  props: {
    // suitForm:{
    //   type:Object,
    //   require:true
    // }
  },
  data() {
    return {
      suitForm: {
        list: []
      },
      shewNameList: [],
      loadingFlag: true,
      clacWidth: 7,
      offestList: [0, 1, 1],
      offtestIndex: 3
    };
  },
  created() {
    // this.initData()
  },
  methods: {
    clacC() {
      let temp = document.body.clientWidth;
      let list = [];
      if (temp < 1500) {
        this.clacWidth = 11;
        for (let i = 0; i < this.suitForm.list.length; i++) {
          if (i % 2 === 0) {
            list.push(0);
          } else {
            list.push(1);
          }
        }
        if (this.suitForm.list.length % 2 === 0) {
          this.offtestIndex = 0;
        } else {
          this.offtestIndex = 1;
        }
      } else {
        this.clacWidth = 7;
        this.offtestIndex = 3;
        for (let i = 0; i < this.suitForm.list.length; i++) {
          if (i % 3 === 0) {
            list.push(0);
          } else {
            list.push(1);
          }
        }
        if (this.suitForm.list.length % 3 === 0) {
          this.offtestIndex = 0;
        } else {
          this.offtestIndex = 1;
        }
      }

      this.offestList = list;
    },
    initData() {
      this.getSwsts();
      this.getShewList();
    },
    // 获取利器名称
    getShewList() {
      get(optnAPI.getAllContent, { perm_id: 10004 })
        .then(res => {
          if (res.data.code === 0) {
            this.shewNameList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg || '';
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 利器套装管理
    getSwsts() {
      this.loadingFlag = true;
      get(swstAPI.getSwsts)
        .then(res => {
          if (res.data.code === 0) {
            this.suitForm = res.data.data;
            for (let i = 0; i < this.suitForm.list.length; i++) {
              this.suitForm.list[i].key = Number(i);
              this.suitForm.list[i].swstContListCopy = JSON.parse(JSON.stringify(this.suitForm.list[i].swst_cont_list));
              for (let j = 0; j < this.suitForm.list[i].swst_cont_list.length; j++) {
                this.suitForm.list[i].swst_cont_list[j].keycopy = Number(j);
                this.suitForm.list[i].swst_cont_list[j].swst_cont_num = Number(this.suitForm.list[i].swst_cont_list[j].swst_cont_num);
              }
            }
            this.clacC();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 1000);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 添加套装
    addSuit() {
      let item = {
        swst_name: '',
        swst_no: '',
        swstContListCopy: [],
        swst_cont_list: [
          {
            swst_cont_name: this.shewNameList[0].param2,
            swst_cont_no: this.shewNameList[0].param1,
            swst_cont_num: 1,
            key: 0
          }
        ],
        key: this.suitForm.list.length
      };
      this.suitForm.list.push(item);
      this.clacC();
    },
    // 新增利器
    addSharpWeapon(val) {
      let item = {
        swst_cont_name: this.shewNameList[0].param2,
        swst_cont_no: this.shewNameList[0].param1,
        swst_cont_num: 1,
        key: this.suitForm.list[val].swst_cont_list.length
      };
      this.suitForm.list[val].swst_cont_list.push(item);
    },
    // 删除利器
    deleteSwstCont(val, val1) {
      if (this.suitForm.list[val].swst_cont_list.length <= 1) {
        return this.$message.warning('至少保留一项');
      }
      this.suitForm.list[val].swst_cont_list.splice(val1, 1);
    },
    // 下拉框变化复制编号
    swstContNameChange(val, val1) {
      for (let i = 0; i < this.shewNameList.length; i++) {
        if (this.shewNameList[i].param2 === this.suitForm.list[val].swst_cont_list[val1].swst_cont_name) {
          this.suitForm.list[val].swst_cont_list[val1].swst_cont_no = this.shewNameList[i].param1;
        }
      }
    },
    // 计数器判断
    handleChange(val, val1) {
      if (!this.suitForm.list[val].swst_cont_list[val1].swst_cont_num) {
        this.suitForm.list[val].swst_cont_list[val1].swst_cont_num = 1;
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.editSwst();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 保存调用接口
    editSwst() {
      this.loadingFlag = true;
      let tableData = [];
      let userInfo = this.$cookies.get('userInfo');
      tableData = JSON.parse(JSON.stringify(this.suitForm.list));
      for (let i in tableData) {
        tableData[i].user_id = userInfo.user_id;
        tableData[i].dept_id = userInfo.dept_id;
        tableData[i].stff_id = userInfo.stff_id;
        tableData[i].dept_team_id = userInfo.dept_team_id;
        tableData[i].acct_id = userInfo.acct_id;
        tableData[i].swstContListCopy = tableData[i].swstContListCopy.filter(item =>
          tableData[i].swst_cont_list.every(item1 => item.swst_cont_id !== item1.swst_cont_id)
        );
      }
      for (let i = 0; i < tableData.length; i++) {
        for (let j = 0; j < tableData[i].swstContListCopy.length; j++) {
          tableData[i].swstContListCopy[j].destroy_flag = 1;
        }
        tableData[i].swst_cont_list = tableData[i].swst_cont_list.concat(tableData[i].swstContListCopy);
      }
      post(swstAPI.editSwst, tableData)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.loadingFlag = false;
            this.$emit('cancelSuit');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          } else if (res.data.code === 888) {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_col {
  height: 415px;
  background-color: rgb(255, 253, 253);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px 10px 0;
  box-shadow: 0px 0px 10px 2px #888888;
}
.vd_col_add {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}
.vd_row {
  display: flex;
  justify-content: center;
}
.vd_delete {
  font-size: 25px;
  margin-top: 2px;
  color: #f00;
  cursor: pointer;
}
.vd_col_copy {
  visibility: hidden;
}
.vd_required {
  color: #f56c6c;
}
::v-deep .el-input-number {
  width: 100%;
}
.vd_dispP {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
