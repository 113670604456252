<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row class="vg_mb_8">
        <el-col :md="12" class="vd_vis"> 1</el-col>
        <el-col :md="12" class="vd_flex_right">
          <el-button size="small" type="primary" @click="suitOpenClick">利器套装管理</el-button>
          <el-button size="small" type="warning" @click="showNeedleRecordDialog">每日断针记录</el-button>
        </el-col>
      </el-row>
      <div class="vd_bor vg_mb_8"></div>
      <div class="vd_search_group">
        <el-form label-width="120px" :inline="true" size="mini" :model="searchForm" ref="searchForm" @keyup.enter.native="getShwesNow()">
          <el-row class="vg_mb_8">
            <el-col :md="8">
              <el-form-item :label="searchForm.typesOf === 1 ? '领用人:' : '更换人:'">
                <el-input size="small" v-model="searchForm.shwe_stff_name" clearable placeholder="请填写操作人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="小组名称:" v-if="searchForm.typesOf === 1">
                <el-input size="small" v-model="searchForm.dept_team_name" clearable placeholder="请填写操作人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="userInfo.acct_id === 1">
              <el-form-item label="所属账套:" label-width="82px">
                <el-select v-model="searchForm.acct_id" filterable placeholder="请选择所属账套" clearable size="small">
                  <el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <!--<el-col :md="16" class="vg_mb_8" v-if="searchForm.typesOf === 1">-->
            <!--  <el-form-item label="更新时间:">-->
            <!--    <el-date-picker-->
            <!--      size="small"-->
            <!--      v-model="searchForm.timeValue2"-->
            <!--      type="datetimerange"-->
            <!--      range-separator="至"-->
            <!--      start-placeholder="开始日期"-->
            <!--      end-placeholder="结束日期"-->
            <!--    >-->
            <!--    </el-date-picker>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <el-col :md="16">
              <el-form-item label="发放时间:">
                <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getShwesNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :md='12'>
              <el-form-item label="开始发放时间:">
                <div class="block">
                  <el-date-picker size="small" v-model="searchForm.timeValue" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md='12'>
              <el-form-item label="最近换针时间:">
                <div class="block">
                  <el-date-picker size="small" v-model="searchForm.timeValue1" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md='14'>
              <el-form-item label="最后回收时间:">
                <div class="block">
                  <el-date-picker size="small" v-model="searchForm.timeValue2" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getShwesNow()" class="vg_ml_16">查询
                </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i
                    class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="hairJump(0)"><i class="el-icon-plus"></i> 新增 发放</el-button>
        <el-button type="warning" size="small" @click="hairJump(1)"><i class="el-icon-plus"></i> 新增 更换</el-button>
        <el-button type="success" size="small" @click="hairJump(2)"><i class="el-icon-plus"></i> 新增 回收</el-button> -->
      </div>
      <el-row>
        <el-col :md="24">
          <div style="display: flex">
            <el-button-group>
              <el-button type="primary" size="small" @click="changeTypeSearch(1)">发放/回收</el-button>
              <el-button type="primary" size="small" @click="changeTypeSearch(2)">更换</el-button>
              <span style="height: 100%" class="flexHV">剩余针数:{{ remainingStitches }}</span>
            </el-button-group>
            <el-button plain size="small" style="margin-left: auto" @click="showFactoryDialog">查看工厂更换总表</el-button>
          </div>
          <el-table
            v-if="searchForm.typesOf === 1"
            @expand-change="expandChange"
            v-loading="isLoding"
            :data="tableData"
            border
            stripe
            highlight-current-row
          >
            <el-table-column v-if="searchForm.typesOf === 1" type="expand" fixed width="50">
              <template v-slot="scope">
                <el-table v-loading="scope.row.detailsLoading" :data="scope.row.detailsList">
                  <el-table-column type="index" fixed width="50" align="center" />
                  <el-table-column label="领用人" prop="shwe_stff_name" />
                  <el-table-column label="领用数量" prop="sumGrantNum" />
                  <el-table-column label="回收数量" prop="sumRecoveryNum">
                    <template v-slot="subScope">
                      <span :class="subScope.row.redStatus === 1 ? 'vd_f00' : null">{{ subScope.row.sumRecoveryNum }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="发放人">
                    <template v-slot="subScope">
                      {{ `${subScope.row.stff_name}(${subScope.row.cptt_name} ${subScope.row.dept_name}${subScope.row.dept_team_name})` }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="发放时间"
                    prop="create_time"
                    show-overflow-tooltip
                    :formatter="val => getDateNoTime(val.create_time, true)"
                  />
                  <el-table-column label="详情">
                    <template v-slot="subScope">
                      <el-link type="primary" @click="detailOpenClick(subScope.row)">操作详情</el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="小组名称" prop="dept_team_name" />
            <el-table-column label="所属部门" prop="dept_name" />
            <el-table-column label="所属账套" prop="acct_no" />
            <el-table-column label="发放人数" prop="sumGrant" />
            <el-table-column label="回收人数" prop="sumRecovery" />
            <el-table-column
              label="发放时间"
              prop="update_time"
              show-overflow-tooltip
              :formatter="val => getDateNoTime(val.update_time, true)"
            />
          </el-table>
          <el-table key="shwe_id" v-else v-loading="isLoding" :data="tableData" border stripe highlight-current-row>
            <el-table-column type="index" fixed width="50" align="center" />
            <el-table-column label="更换人" prop="shwe_stff_name" />
            <el-table-column label="所属部门" prop="shwe_dept_name" />
            <el-table-column label="所属小组" prop="shwe_dept_team_name" />
            <el-table-column label="更换数量" prop="shwe_deta_num" />
            <el-table-column
              label="更新时间"
              prop="update_time"
              show-overflow-tooltip
              :formatter="val => getDateNoTime(val.update_time, true)"
            />
            <el-table-column label="发放人" prop="stff_name" show-overflow-tooltip :formatter="val => helper.personCombina(val)" />
            <el-table-column label="图片" prop="imge_list">
              <template v-slot="scope">
                <div v-for="item in scope.row.imge_list" :key="`imageId${item.imge_id}`">
                  <imgColorPop :macpFlag="helper.picUrl(item.imge_url, 'l', null, 0)" :macpFlagO="2"></imgColorPop>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
    <!-- <el-dialog :title="title" :visible.sync="hairDialogVisible" :before-close="closeDialog" width="50%">
      <el-form :model="addFrom" :inline="true" ref="addFrom">
        <el-row>
          <el-col :md="12">
            <el-form-item label="员工卡号">
              <el-input size="small" clearable show-word-limit maxlength="10" ref="rfidNoRef" @change="inputChange"
                v-model="addFrom.rfid_no" placeholder="请刷员工卡号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="领用人">
              <el-input size="small" disabled v-model="addFrom.shwe_stff_name" placeholder="请输入领用人"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="12">
            <el-form-item label="领用人部门">
              <el-input size="small" disabled v-model="addFrom.shwe_dept_name" placeholder="请输入领用人部门"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="领用人小组">
              <el-input size="small" disabled v-model="addFrom.shwe_dept_team_name" placeholder="请输入领用人小组"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="12">
            <el-form-item label="利器名称">
              <el-select v-model="addFrom.shwe_name" placeholder="请选择利器名称" clearable size="small">
                <el-option v-for="item in shewNameList" :key="item.id" :label="item.param1" :value="item.param1">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="数量">
              <el-input size="small" clearable show-word-limit maxlength="5"
                @input="addFrom.shwe_deta.shwe_deta_num = helper.keepTNum(addFrom.shwe_deta.shwe_deta_num)"
                v-model="addFrom.shwe_deta.shwe_deta_num" placeholder="请输入数量"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="clearableDialog">取消</el-button>
        <el-button size="small" type="primary" @click="submitDia">保存</el-button>
      </span>
    </el-dialog> -->
    <el-dialog title="操作详情" center width="50%" :visible.sync="detailVisible">
      <div class="vd_detail">
        <!--        <div class="vg_mt_16">
          <el-image
            class="vd_img"
            :z-index="9999"
            :preview-src-list="srcList"
            :class="index % 3 === 0 ? '' : 'vg_ml_8'"
            v-for="(item, index) in srcList"
            :key="index"
            lazy
            :src="item"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline vd_img_icon" style="font-size: 50px"></i>
            </div>
          </el-image>
        </div>-->
        <span>07:00 ~ 12:00</span>
        <el-table class="vg_mb_16 vg_mt_8" :data="detailTableData.mornList" style="width: 50%" border>
          <el-table-column prop="shar_name" label="利器名称" align="center" width="180">
            <template v-slot="scope">
              <span :style="scope.row.recovery < scope.row.grant ? 'color:red;' : null">{{ scope.row.shar_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="grant" label="发放数量" align="center"></el-table-column>
          <el-table-column prop="recovery" label="回收数量" align="center">
            <template v-slot="scope">
              <span :style="scope.row.recovery < scope.row.grant ? 'color:red;' : null">{{ scope.row.recovery }}</span>
            </template>
          </el-table-column>
        </el-table>
        <span>12:00 ~ 17:30</span>
        <el-table class="vg_mb_16 vg_mt_8" :data="detailTableData.noonList" style="width: 50%" border>
          <el-table-column prop="shar_name" label="利器名称" align="center" width="180">
            <template v-slot="scope">
              <span :style="scope.row.recovery < scope.row.grant ? 'color:red;' : null">{{ scope.row.shar_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="grant" label="发放数量" align="center"></el-table-column>
          <el-table-column prop="recovery" label="回收数量" align="center">
            <template v-slot="scope">
              <span :style="scope.row.recovery < scope.row.grant ? 'color:red;' : null">{{ scope.row.recovery }}</span>
            </template>
          </el-table-column>
        </el-table>
        <span>17:30 ~ 07:00</span>
        <el-table class="vg_mb_16 vg_mt_8" :data="detailTableData.nightList" style="width: 50%" border>
          <el-table-column prop="shar_name" label="利器名称" align="center" width="180">
            <template v-slot="scope">
              <span :style="scope.row.recovery < scope.row.grant ? 'color:red;' : null">{{ scope.row.shar_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="grant" label="发放数量" align="center"></el-table-column>
          <el-table-column prop="recovery" label="回收数量" align="center">
            <template v-slot="scope">
              <span :style="scope.row.recovery < scope.row.grant ? 'color:red;' : null">{{ scope.row.recovery }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="利器套装管理" width="70%" :visible.sync="suitVisible" :before-close="suitHandleClose">
      <SuitList @cancelSuit="cancelSuit" ref="SuitList"></SuitList>
    </el-dialog>
    <el-dialog title="每日断针记录" width="60%" :visible.sync="needleRecordVisible" :before-close="() => (needleRecordVisible = false)">
      <el-row>
        <div style="width: 100%; height: 60px">
          <el-col :md="2" class="flexHV" style="height: 50px">日期</el-col>
          <el-col :md="8" class="flexHV" style="height: 50px">
            <el-date-picker
              v-model="needleRecordDate"
              type="month"
              placeholder="选择年月"
              value-format="yyyy-MM"
              @change="dateChangeSearch"
            />
          </el-col>
          <div v-if="userInfo.acct_id === 1">
            <el-col :md="2" class="flexHV" style="height: 50px">账套</el-col>
            <el-col :md="8" class="flexHV" style="height: 50px">
              <el-select v-model="acctId" filterable placeholder="请选择所属账套" clearable @change="acctChangeSearch">
                <el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"></el-option>
              </el-select>
            </el-col>
          </div>
        </div>
        <el-row class="dateDiv">
          <!--          <el-calendar>
            <template #dateCell="{ date, data }">
              <p :class="data.isSelected ? 'is-selected' : ''">
                {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : '' }}
              </p>
            </template>
          </el-calendar>-->
          <div v-for="(item, index) in Number(maxDay)" :key="index">
            <el-col style="width: 14.285%">
              <div class="dateCell">
                <div>{{ index + 1 }}</div>
                <div v-if="dateList.get(`${index + 1}`) && currentMonth === searchMonth" class="dateStatus flexHV">
                  <el-button type="text" style="font-size: 18px" @click="showImageDialog(dateList.get(`${index + 1}`))">查看图片</el-button>
                </div>
                <div v-else-if="index + 1 > currentDay && currentMonth === searchMonth" class="dateStatus flexHV">暂无</div>
                <div v-else style="color: red" class="dateStatus flexHV">未上传</div>
              </div>
            </el-col>
          </div>
        </el-row>
      </el-row>
    </el-dialog>
    <el-dialog title="查看图片" :visible.sync="imageDialogVisable">
      <div style="text-align: center" v-if="imageDialogVisable">
        <el-image style="width: 720px; height: 800px" :src="imageUrl"></el-image>
      </div>
    </el-dialog>
    <el-dialog title="工厂更换总表" width="70%" :visible.sync="factoryReplaceTotalTableVisible">
      <search-table
        ref="factory"
        :totalPage="factoryTotalPage"
        :data="factoryTableData"
        :tableRowKey="'factory'"
        :columns="tableProperties"
        :pageSize="4"
        @getTableData="getFactoryTableData"
      >
        <template v-slot:picture="scope">
          <div style="display: flex">
            <div v-for="item in scope.row.images" :key="`imageId${item.imge_id}`" class="vg_mr_8">
              <el-image
                lazy
                :z-index="9999"
                style="height: 75px"
                :preview-src-list="[helper.picUrl(item.imge_url, 'l', null, 0)]"
                :src="helper.picUrl(item.imge_url, 'l', null, 0)"
              />
            </div>
          </div>
        </template>
      </search-table>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { shweAPI } from '@api/modules/shwe';
import { userAPI } from '@api/modules/user';
import { optnAPI } from '@api/modules/optn';
import { acctAPI } from '@api/modules/acct';
import pubPagination from '@/components/common/pubPagination';
import SuitList from './TabChild/SuitList.vue';
import helper from '@assets/js/helper.js';
import { recordAPI } from '@api/modules/record';
import { getDate, getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/SearchTableV2.vue';
import imgColorPop from '@/views/component/imgColorPop';

export default {
  name: 'ShweList',
  components: {
    SearchTable,
    pubPagination,
    SuitList,
    imgColorPop
  },
  data() {
    return {
      factoryTotalPage: 0,
      remainingStitches: 0,
      factoryTableData: [],
      tableProperties: [
        this.$store.state.index,
        {
          label: '所属账套',
          prop: 'acctId',
          itemType: 'select',
          options: [],
          input: true,
          sortable: false,
          widthAuto: true,
          needOtherColumn: true,
          formatter: val => val.acctName,
          columnShow: this.$cookies.get('userInfo').acct_id === 1
        },
        { label: '部门', prop: 'deptName', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '更换总量', prop: 'total', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '剩余针数', prop: 'number', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '断针记录', prop: 'brokenNeedle', itemType: 'input', input: false, sortable: false, widthAuto: true },
        {
          label: '断针合集照片',
          prop: 'picture',
          itemType: 'input',
          input: false,
          sortable: false,
          labelWidth: '300px'
          // subItem: {
          //   type: 'image',
          //   thumbnail: row => helper.picUrl(row.picture, 'l'),
          //   image: row => helper.picUrl(row.picture, 'l')
          // }
        },
        {
          label: '统计时间',
          prop: 'totalTime',
          itemType: 'date',
          input: true,
          sortable: false,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        }
      ],
      factoryReplaceTotalTableVisible: false,
      imageUrl: '',
      imageDialogVisable: false,
      acctId: '',
      needleRecordDate: '',
      needleRecordVisible: false,
      maxDay: 0,
      currentDay: new Date().getUTCDate(),
      currentMonth: Number(new Date().getUTCFullYear() + '0' + (new Date().getUTCMonth() + 1)),
      searchMonth: Number(new Date().getUTCFullYear() + '0' + (new Date().getUTCMonth() + 1)),
      dateList: new Map(),
      searchForm: {
        shwe_stff_name: null,
        shwe_type: null,
        acct_id: null,
        timeValue: [],
        typesOf: 1
      },
      addFrom: {
        rfid_no: null,
        shwe_stff_name: null,
        shwe_dept_name: null,
        shwe_dept_team_name: null,
        shwe_name: null,
        shwe_deta: {
          shwe_deta_num: null
        }
      },
      addFromObj: {},
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      deptTeamsGroupOpt: [
        { id: 0, label: '发放' },
        { id: 1, label: '更换' },
        { id: 2, label: '回收' }
      ],
      shewNameList: [],
      btn: {},
      isLoding: true,
      hairDialogVisible: false,
      modeFlag: 0,
      title: '',
      detailVisible: false, //操作详情弹框
      suitVisible: false, //套装弹框
      detailTableData: [],
      srcList: [],
      userInfo: this.$cookies.get('userInfo'),
      typeFlag: '', //发放类型
      acctList: []
    };
  },
  created() {
    if (this.$route.query.createTime) {
      let time = Number(this.$route.query.createTime) * 1000;
      this.searchForm.timeValue.push(new Date(time));
      this.searchForm.timeValue.push(new Date(time));
    }
    this.initData();
  },

  filters: {
    // 产品类型转换
    formatQmttType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    },
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row);
    }
  },
  mounted() {
    this.tableProperties = this.tableProperties.filter(x => x.columnShow !== false);
    this.clacC();
  },
  methods: {
    getDateNoTime,
    showImageDialog(url) {
      this.imageDialogVisable = true;
      this.imageUrl = helper.picUrl(url, 'l');
    },
    dateChangeSearch(val) {
      let [year, month] = val.split('-');
      this.searchMonth = Number(year + month);
      this.getNeedleRecordList({ year: year, month: month, acctId: this.acctId });
    },
    acctChangeSearch(val) {
      let [year, month] = this.needleRecordDate?.split('-') ?? [null, null];
      this.getNeedleRecordList({ year: year, month: month, acctId: val });
    },
    getNeedleRecordList(val) {
      recordAPI.getNeedleRecordList(val).then(({ data }) => {
        this.maxDay = data.lastDayOfMonth?.split('-')[2];
        if (data.data.length === 0) this.dateList = new Map();
        else {
          this.dateList = new Map();
          data.data.forEach(({ createTime, imgUl }) => this.dateList.set(getDate(createTime, 'day'), imgUl));
          console.log(111, this.dateList);
        }
      });
    },
    showNeedleRecordDialog() {
      this.getNeedleRecordList({});
      this.needleRecordVisible = true;
    },
    clacC() {
      // let temp = document.body.clientWidth;
    },
    initData() {
      this.isLoding = true;
      this.getShwes();
      this.getShewList();
      if (this.userInfo.acct_id === 1) {
        this.getAcct();
      }
    },
    // 获取公司抬头信息
    getShwes() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const timeNewVal2 = this.helper.getTime(this.searchForm.timeValue2);
      get(shweAPI.getShwes, {
        shwe_stff_name: this.searchForm.shwe_stff_name,
        acct_id: this.searchForm.acct_id,
        shwe_type: this.searchForm.shwe_type,
        typesOf: this.searchForm.typesOf,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        start_time2: timeNewVal2.startTime,
        end_time2: timeNewVal2.endTime,
        page_no: this.currentPage
      }).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.remainingStitches = res.data.data.number;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.isLoding = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg);
        }
        this.acctList = res.data.data;

        this.tableProperties[1].options = Array.from(res.data.data, ({ acct_no, acct_id }) => ({ value: acct_id, label: acct_no }));
      });
    },
    // 获取利器名称
    getShewList() {
      get(optnAPI.getAllContent, { perm_id: 10004 }).then(res => {
        if (res.data.code === 0) {
          this.shewNameList = res.data.data;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 关闭利器套装管理
    cancelSuit() {
      this.suitVisible = false;
    },
    // 取消新增
    clearableDialog() {
      this.hairDialogVisible = false;
      this.clearDialog();
    },
    // 保存新增
    submitDia() {
      if (this.addFrom.rfid_no && this.addFrom.shwe_name && this.addFrom.shwe_deta.shwe_deta_num) {
        this.$confirm('确认保存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.saveInfo();
          })
          .catch(() => {});
      } else {
        this.$message.warning('请把内容补充完整');
      }
    },
    saveInfo() {
      let userInfo = this.$cookies.get('userInfo');
      let params = {};
      params = Object.assign(this.addFrom);
      params.stff_name = userInfo.stff_name;
      params.stff_id = userInfo.stff_id;
      params.user_id = userInfo.user_id;
      params.dept_id = userInfo.dept_id;
      params.dept_team_id = userInfo.dept_team_id;
      params.shwe_user_id = this.addFromObj.user_id;
      params.shwe_stff_id = this.addFromObj.stff_id;
      params.shwe_dept_id = this.addFromObj.dept_id;
      params.shwe_dept_team_id = this.addFromObj.dept_team_id;
      params.shwe_deta.shwe_deta_type = this.modeFlag;
      post(shweAPI.addShwe, params).then(res => {
        if (res.data.code === 0) {
          this.clearDialog();
          this.hairDialogVisible = false;
          this.$message.success('保存成功');
          this.initData();
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 打开利器套装管理
    suitOpenClick() {
      this.suitVisible = true;
      setTimeout(() => {
        this.$refs.SuitList.loadingFlag = true;
        this.$refs.SuitList.initData();
      }, 200);
    },
    // 关闭利器套装管理时提醒
    suitHandleClose() {
      this.$confirm('此操作将不保存您更改的内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.suitVisible = false;
          this.$message({ type: 'info', message: '已关闭' });
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消关闭' });
        });
    },
    // 打开详情弹框
    async detailOpenClick(row) {
      await shweAPI.getOperationdetails({ shwe_stff_id: row.shwe_stff_id, create_time: row.create_time }).then(({ data }) => {
        this.detailTableData.mornList = data.mornList;
        this.detailTableData.noonList = data.noonList;
        this.detailTableData.nightList = data.nightList;
      });
      await (this.detailVisible = true);
    },
    // 刷卡
    inputChange() {
      if (this.addFrom.rfid_no) {
        get(userAPI.getUserByRfidNo, { rfid_no: this.addFrom.rfid_no }).then(res => {
          if (res.data.code === 0) {
            this.addFromObj = res.data.data.form;
            this.addFrom.shwe_stff_name = res.data.data.form.stff_name;
            this.addFrom.shwe_dept_name = res.data.data.form.dept_name;
            this.addFrom.shwe_dept_team_name = res.data.data.form.dept_team_name;
            this.$refs.rfidNoRef.blur();
          } else if (res.data.code === 2) {
            this.$message.warning('该卡号暂未绑定员工，请重新刷卡');
            this.addFrom.rfid_no = null;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        });
      } else {
        this.addFrom.shwe_stff_name = null;
        this.addFrom.shwe_dept_name = null;
        this.addFrom.shwe_dept_team_name = null;
      }
    },
    // 操作类型
    formatType(row) {
      if (row.shwe_type === 0) {
        return '发放';
      } else if (row.shwe_type === 1) {
        return '更换';
      } else if (row.shwe_type === 2) {
        return '回收';
      }
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        shwe_stff_name: null,
        shwe_type: null,
        acct_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        },
        typesOf: 1
      };
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.isLoding = true;
      this.initData();
    },
    // 查询方法
    getShwesNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.isLoding = true;
      this.initData();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.isLoding = true;
      this.getShwes();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump(`/shwe_detail`, { perm_id: permId, form_id: row.shwe_id });
    },
    // 打开弹框新增
    hairJump(val) {
      this.hairDialogVisible = true;
      this.modeFlag = val;
      if (val === 0) {
        this.title = '利器发放';
      } else if (val === 2) {
        this.title = '利器回收';
      } else if (val === 1) {
        this.title = '利器更换';
      }
      setTimeout(() => {
        this.$refs.rfidNoRef.focus();
      }, 200);
    },
    // 关闭弹框
    closeDialog() {
      this.hairDialogVisible = false;
      this.clearDialog();
    },
    // 清空弹框内容
    clearDialog() {
      this.addFrom.rfid_no = null;
      this.addFrom.shwe_stff_name = null;
      this.addFrom.shwe_dept_name = null;
      this.addFrom.shwe_dept_team_name = null;
      this.addFrom.shwe_name = null;
      this.addFrom.shwe_deta.shwe_deta_num = null;
    },
    changeTypeSearch(type) {
      this.searchForm.typesOf = type;
      this.getShwesNow();
    },
    async showFactoryDialog() {
      await (this.factoryReplaceTotalTableVisible = true);
      await this.getFactoryTableData();
    },
    getFactoryTableData() {
      shweAPI.getSgetShwesReplaceListhwes(this.$refs.factory.searchForm).then(({ data }) => {
        this.factoryTotalPage = data.total;
        this.factoryTableData = data.list;
      });
    },
    async expandChange(row, expandedRows) {
      if (!row.detailsLoading) {
        row.detailsLoading = true;
        return;
      }
      await (row.expandShow = true);
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const timeNewVal2 = this.helper.getTime(this.searchForm.timeValue2);
      console.log(timeNewVal, timeNewVal2);
      let requestBody = {
        shwe_dept_team_id: row.shwe_dept_team_id,
        start_time: timeNewVal2.startTime,
        end_time: timeNewVal2.endTime,
        start_time2: timeNewVal.startTime,
        end_time2: timeNewVal.endTime
      };
      await shweAPI.getSharpweaDetailsRequst(requestBody).then(({ data }) => {
        row.detailsLoading = false;
        row.detailsList = data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-table__expanded-cell {
  padding: 0;
}
.font_w_r {
  //字体红色
  color: red;
  font-weight: 700;
}

.vd_bor {
  border-bottom: 1px dashed #000000;
}

.vd_flex_right {
  display: flex;
  justify-content: flex-end;
}

.vd_detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vd_img {
  width: 300px;
  // height: 200px;
}

.vd_img_icon {
  font-size: 50px;
}

::v-deep .image-slot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vd_f00 {
  color: #f00;
}

.vd_vis {
  visibility: hidden;
}

.dateCell {
  height: 100px;
  border-bottom: 2px solid #efefef;
  border-right: 2px solid #efefef;
  font-size: 18px;
}

.dateDiv {
  border-top: 2px solid #efefef;
  border-left: 2px solid #efefef;
}

.dateStatus {
  height: 55px;
}
.flexHV {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
